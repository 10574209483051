import React from "react";
import { Brand } from "schema-dts";
import JSONLDTag from "./JSONLDTag";

type Props = {
  absolutePath: string;
  logo: string;
  name: string;
  description: string;
  sameAs: string[];
};

export default function JSONLDBrand(props: Props) {
  return (
    <JSONLDTag<Brand>
      data-testid="brand-ld"
      json={{
        "@context": "https://schema.org",
        "@type": "Brand",
        "@id": `${props.absolutePath}#brand`,
        logo: props.logo,
        name: props.name,
        sameAs: props.sameAs,
        description: props.description,
        url: props.absolutePath,
        mainEntityOfPage: props.absolutePath,
      }}
    />
  );
}
