import cx from "classnames";
import React, { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { trackCustomEvent } from "../utilities/analytics";

type Props = {
  className?: string;
  children: ReactNode;
};

export default function Main({ className, children }: Props) {
  const [scrollDepthRef, inView] = useInView({ triggerOnce: true });
  useEffect(() => {
    if (!inView) {
      return;
    }

    trackCustomEvent({
      category: "Custom",
      action: "Page Scroll",
      label: "Scroll Depth - 50%",
      nonInteraction: true,
    });
  }, [inView]);

  return (
    <main
      id="main-content"
      className={cx("main bg-white relative z-10", className)}
    >
      {children}
      <div
        ref={scrollDepthRef}
        className="absolute w-full"
        style={{ top: "50%" }}
      />
    </main>
  );
}
