import React, { ComponentPropsWithoutRef } from "react";
import { Thing, WithContext } from "schema-dts";

/**
 * All valid HTML script props, minus the ones we hard-code
 */
type ScriptProps = Omit<
  ComponentPropsWithoutRef<"script">,
  "type" | "dangerouslySetInnerHTML"
>;

interface Props<T extends Thing> extends ScriptProps {
  json: WithContext<T>;
}

export default function JSONLDTag<T extends Thing>({
  json,
  ...rest
}: Props<T>) {
  return (
    <script
      {...rest}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(json),
      }}
    />
  );
}
