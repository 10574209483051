import { graphql, useStaticQuery } from "gatsby";

import { DomainPathQuery } from "../../graphql-types";

export default function useDomainPath() {
  const { domain } = useStaticQuery<DomainPathQuery>(graphql`
    query DomainPath {
      domain {
        path
      }
    }
  `);

  return domain?.path;
}
