import { graphql, useStaticQuery } from "gatsby";

import { CurrentYearQuery } from "../../graphql-types";

export default function useCurrentYear() {
  const { website } = useStaticQuery<CurrentYearQuery>(graphql`
    query CurrentYear {
      website {
        currentYear
      }
    }
  `);
  return website?.currentYear;
}
