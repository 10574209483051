import React from "react";
import { Maybe } from "../../graphql-types";

type Props = {
  oneTrustId?: Maybe<string>;
};

export default function OneTrustShowSettings({
  oneTrustId,
}: Props) {
  if (!oneTrustId) return null;

  return (
    // <!-- OneTrust Cookies Settings button start -->
    <button id="ot-sdk-btn" className="ot-sdk-show-settings">
      Cookie Settings
    </button>
  );
}
