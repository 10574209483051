import { IGatsbyImageData } from "gatsby-plugin-image";

type Props = {
  logo: IGatsbyImageData;
  sameAs: string[];
};

/* istanbul ignore next */
export default function JSONLDTagOrganization({ logo, sameAs }: Props) {
  return null;
}
