import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

import type { NavQueryQuery } from "../../graphql-types";

import cx from "classnames";
import { trackCustomEvent } from "../utilities/analytics";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";

const STATIC_QUERY = graphql`
  query NavQuery {
    website {
      navLinks {
        ... on Message {
          path
          title
        }
        ... on Subject {
          path
          title: navigationTitle
        }
        ... on Hyperlink {
          path: url
          title
        }
        ... on SustainabilityLandingPage {
          path
          title
        }
        ... on SignUpFormWidget {
          path
          title
        }
        ... on ContactUs {
          path
          title
        }
        ... on Faq {
          path
          title
        }
        ... on Variant {
          path
          title: htmlTitle
        }
        ... on SustainabilityCategory {
          path
          title
        }
        ... on LandingPage {
          path
          title
        }
      }
    }
  }
`;

type Props = {
  absolutePath: string;
};

function trackHamburgerClickedOpen() {
  trackCustomEvent({
    category: "Engagement",
    action: "Click",
    label: "Hamburger Icon (Open)",
  });
}

export default function Nav({ absolutePath }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // TODO: move this to GQL Fragment instead of Static Query
  const data = useStaticQuery<NavQueryQuery>(STATIC_QUERY);
  const { website } = data;
  if (!website) {
    throw new Error("Could not find website node");
  }
  const navModalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!navModalRef.current) {
      return;
    }
    const dialog = navModalRef.current;

    function handleClose() {
      setIsMenuOpen(false);
    }
    dialog.addEventListener("close", handleClose);
    return () => {
      dialog.removeEventListener("close", handleClose);
    };
  }, [navModalRef]);

  const { navLinks } = website;
  if (navLinks.length === 0) {
    return null;
  }

  return (
    <nav className="w-full">
      <button
        id="menu-button"
        aria-controls="menu"
        aria-expanded={isMenuOpen}
        aria-haspopup="dialog"
        className="hamburger lg:hidden"
        onClick={() => {
          trackHamburgerClickedOpen();
          setIsMenuOpen(true);
          // navModalRef.current?.showModal?.();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
          <path
            fill="none"
            d="M16 32h32s11.723-.306 10.75-11c-.25-2.75-1.644-4.971-2.869-7.151C50.728 7.08 42.767 2.569 33.733 2.054 33.159 2.033 32.599 2 32 2 15.432 2 2 15.432 2 32c0 16.566 13.432 30 30 30 16.566 0 30-13.434 30-30C62 15.5 48.5 2 32 2S1.875 15.5 1.875 32"
          />
        </svg>
        <span className="sr-only">Menu</span>
      </button>
      <dialog
        id="menu"
        className="daisy-modal daisy-modal-bottom sm:daisy-modal-middle"
        ref={navModalRef}
      >
        <div className="daisy-modal-box">
          <form method="dialog">
            <button
              className="daisy-btn daisy-btn-sm daisy-btn-circle daisy-btn-ghost absolute right-2 top-2"
              aria-hidden
              aria-controls="menu"
            >
              ✕<span className="sr-only">Close Menu</span>
            </button>
          </form>
          <ul id="nav-list" data-cy="primary-nav-list">
            {navLinks.map(({ path, __typename, title }) => {
              const LinkComponent =
                __typename === "Hyperlink" ? ExternalLink : InternalLink;
              const active = absolutePath.includes(path);
              return (
                <li key={path + title} className="flex py-2">
                  <LinkComponent
                    href={path}
                    className={cx(
                      "relative text-2xl font-semibold mx-auto text-center border-b border-transparent hover:border-current",
                      {
                        "text-[color:var(--nav-active-color)] hover:text-[color:var(--nav-link-color)] border-current":
                          active,
                      },
                      {
                        "text-[color:var(--nav-link-color)] hover:text-[color:var(--nav-active-color)]":
                          !active,
                      }
                    )}
                  >
                    {title}
                  </LinkComponent>
                </li>
              );
            })}
          </ul>
        </div>
        <form method="dialog" className="daisy-modal-backdrop">
          <button>Close Menu</button>
        </form>
      </dialog>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        document.getElementById("menu-button").addEventListener("click", () => {
          document.getElementById("menu").showModal();
        });
        `,
        }}
      />
      <ul className="hidden lg:flex lg:items-center lg:justify-end lg:ml-auto lg:h-full gap-8 pr-8">
        {navLinks.map(({ path, __typename, title }) => {
          const LinkComponent =
            __typename === "Hyperlink" ? ExternalLink : InternalLink;
          const active = absolutePath.includes(path);
          return (
            <li key={path + title} className="inline-flex py-0">
              <LinkComponent
                href={path}
                className={cx(
                  "relative font-semibold mx-auto text-[.9375rem] text-center border-b border-transparent hover:border-current",
                  {
                    "text-[color:var(--nav-active-color)] hover:text-[color:var(--nav-link-color)] border-current":
                      active,
                  },
                  {
                    "text-[color:var(--nav-link-color)] hover:text-[color:var(--nav-active-color)]":
                      !active,
                  }
                )}
              >
                {title}
              </LinkComponent>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
