import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import {
  FooterFlexibleHomeFragment,
  FooterFlexibleQuery,
} from "../../../graphql-types";
import useCurrentYear from "../../hooks/useCurrentYear";
import CustomFooterLinks from "../CustomFooterLinks";
import ExternalLink from "../ExternalLink";
import Image from "../GatsbyImage";
import InternalLink from "../InternalLink";
import LanguageSelector from "../LanguageSelector";
import { RichTextRenderer } from "../RichTextRenderer";
import IconSignLanguage from "../icons/IconSignLanguage";

export type Props = {
  home: FooterFlexibleHomeFragment;
  hideDisclaimerMessage?: boolean;
};

// todo: infer this type from FooterHomeFragment somehow
type LinksArray = Array<{ path: string; title: string }>;

function Group({
  groupLabel,
  links,
}: {
  groupLabel?: string;
  links: LinksArray;
}) {
  if (links.length === 0) return null;
  return (
    <div className="flex flex-col pl-3 my-5 lg:mx-10 lg:border-l lg:border-gray-300">
      <h3 className="block mb-4 text-base font-bold text-tuna">{groupLabel}</h3>
      <ul className="min-w-24">
        {links?.map(({ path, title }, index: number) => {
          // return JSON.stringify(link);

          const isExternal =
            path.startsWith("https://") &&
            !path.startsWith(
              `https://www.${process.env.GATSBY_SITE_DOMAIN}${process.env.GATSBY_PATH_PREFIX}`
            );
          const Link = isExternal ? ExternalLink : InternalLink;

          return (
            <li key={path + index}>
              <Link
                href={path}
                className={cx("animate-underline", {
                  "after:block after:mb-2": index < links.length - 1,
                })}
                {...(isExternal && { target: "_blank" })}
              >
                {path.startsWith("https://pslz.in/unilever") && (
                  // One-off for Brazil
                  // https://www.notion.so/2bb5f95156f44c18afd59ddb9ca534c3?v=5c5aeaef64124d9aa87687814accfe5f&p=135538308f744f968b00fbcd4ecc198e
                  <IconSignLanguage className="mr-1" />
                )}
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default function Footer({ home, hideDisclaimerMessage }: Props) {
  // TODO: move this to page query
  const { unileverLogo } = useStaticQuery<FooterFlexibleQuery>(graphql`
    query FooterFlexible {
      unileverLogo: file(relativePath: { eq: "unilever-logo.gif" }) {
        publicURL
      }
    }
  `);

  // TODO: move this to page query
  const currentYear = useCurrentYear();
  const hasSocialLinks = Boolean(
    home.legals.socialChannels && home.legals.socialChannels.length > 0
  );
  const hasGroupLinks = Boolean(
    home.legals.group1?.length ||
      home.legals.group2?.length ||
      home.legals.group3?.length
  );

  const hasLanguages =
    home.domain.languages && home.domain.languages.length > 0;

  const disclaimerMessagePath = home?.brand.disclaimerMessage?.path;

  return (
    <footer data-cy="footer">
      {home.legals?.blurb?.raw && (
        <RichTextRenderer
          className="p-6 text-xs font-medium text-center bg-white text-silver-chalice"
          component="div"
          raw={home.legals.blurb.raw}
        />
      )}

      {home.brand.disclaimer &&
        !hideDisclaimerMessage &&
        !home.brand?.disclaimerMessage?.teaserImage && (
          <div className="bg-amber-300/60">
            <div className="flex items-center max-w-container p-6 mx-auto text-base font-medium text-yellow-900">
              <svg
                width="28"
                height="28"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Warning icon"
                className="grow-0 shrink-0 mr-6"
              >
                <path
                  d="M14 0C6.268 0 0 6.268 0 14s6.268 14 14 14 14-6.268 14-14A14 14 0 0 0 14 0zm.298 22.043a1.787 1.787 0 1 1 0-3.575 1.787 1.787 0 0 1 0 3.575zm0-5.164c-.987 0-1.787-.8-1.787-1.788V7.745a1.787 1.787 0 1 1 3.574 0v7.346c0 .987-.8 1.788-1.787 1.788z"
                  fill="#796C3E"
                />
              </svg>
              <span>
                {disclaimerMessagePath && (
                  <InternalLink
                    href={disclaimerMessagePath}
                    title={home.brand.disclaimer}
                    className="text-brown hover:underline block"
                  >
                    {home.brand.disclaimer}
                  </InternalLink>
                )}
                {!disclaimerMessagePath && home.brand.disclaimer}
              </span>
            </div>
          </div>
        )}

      {home.brand.disclaimer &&
        !hideDisclaimerMessage &&
        disclaimerMessagePath &&
        home.brand?.disclaimerMessage?.teaserImage && (
          <div className="max-w-lg mx-auto bg-golden-sand py-4 mb-12">
            <InternalLink
              href={disclaimerMessagePath}
              title={home.brand.disclaimer}
              className="text-brown hover:underline"
            >
              <Image
                alt={
                  home.brand.disclaimerMessage.teaserImage.description ||
                  home.brand.disclaimer
                }
                image={home.brand.disclaimerMessage.teaserImage.gatsbyImageData}
              />
            </InternalLink>
          </div>
        )}

      {(hasSocialLinks || hasGroupLinks) && (
        <div className="bg-gray-50">
          <div className="z-10 flex flex-col items-start max-w-container p-6 mx-auto lg:flex-row">
            {hasSocialLinks && (
              <div className="my-5 lg:order-0">
                {home.legals.socialChannelsLabel && (
                  <h3 className="block mb-6 text-base font-bold text-tuna">
                    {home.legals.socialChannelsLabel}
                  </h3>
                )}
                <ul className="flex gap-1">
                  {home.legals.socialChannels?.map((channel) => {
                    const channelName = channel.name.toLowerCase();
                    return (
                      <li key={channel.url} className="grow-0 shrink-0">
                        <ExternalLink
                          href={channel.url}
                          target="_blank"
                          showExternalIcon={false}
                          className={cx(
                            "rounded-full flex items-center justify-center cursor-pointer outline-none transition-colors w-16 h-16 hover:ring-4 focus:ring-4 ring-link-500",
                            {
                              "bg-[color:#0075fb]": channelName === "facebook",
                              "bg-[color:#000000]": channelName === "twitter",
                              "bg-[color:#ff0000]": channelName === "youtube",
                              "bg-[color:#2787f4]": channelName === "vk",
                              "bg-[color:#e70022]": channelName === "pinterest",
                              "bg-[color:#1db954]": channelName === "spotify",
                              "bg-[color:#000]": channelName === "tiktok",
                              "bg-instagram": channelName === "instagram",
                            }
                          )}
                        >
                          <img
                            width="28"
                            height="28"
                            alt={""}
                            src={channel.image.localFile?.publicURL || ""}
                            className="size-[28px] brightness-0 invert"
                          />
                          <span className="sr-only">{channel.name}</span>
                        </ExternalLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {hasGroupLinks && (
              <>
                <Group
                  groupLabel={home.legals.group1Label as string}
                  links={home.legals.group1 as LinksArray}
                />
                {home.legals.group2 && home.legals.group2.length > 0 && (
                  <Group
                    groupLabel={home.legals.group2Label as string}
                    links={home.legals.group2 as LinksArray}
                  />
                )}
                <Group
                  groupLabel={home.legals.group3Label as string}
                  links={home.legals.group3 as LinksArray}
                />
              </>
            )}
            <CustomFooterLinks />
          </div>
        </div>
      )}
      {hasLanguages && (
        <LanguageSelector
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          languages={home.domain.languages!}
        />
      )}

      <div className="bg-[color:var(--footer-bg-color)] ">
        <div className="z-10 max-w-container p-6 mx-auto lg:h-24 lg:flex lg:items-center lg:py-0 lg:pr-28 lg:gap-6">
          <ExternalLink
            className="relative text-sm font-bold text-white hover:text-white animate-underline"
            href="https://www.unilever.com"
          >
            © Unilever {currentYear}
          </ExternalLink>
          <ul className="flex flex-wrap pt-2 lg:mt-0 mt-2 border-t border-white lg:inline-flex lg:mt-0 lg:border-t-0 lg:pt-0 gap-2 lg:gap-6">
            {(home.legals.notices as LinksArray)?.map(
              ({ path, title }, index) => (
                <li key={path + index}>
                  <ExternalLink
                    href={path}
                    target="_blank"
                    className={
                      "animate-underline text-sm font-medium text-white hover:text-white"
                    }
                  >
                    {title}
                  </ExternalLink>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      {unileverLogo?.publicURL && (
        <div className="z-20 flex justify-end pl-6">
          <ExternalLink href="https://www.unilever.com">
            <img
              alt="Unilever Logo"
              src={unileverLogo.publicURL}
              loading="lazy"
              className="size-24 lg:-mt-24"
            />
          </ExternalLink>
        </div>
      )}
    </footer>
  );
}

export const fragments = {
  home: graphql`
    fragment FooterFlexibleGroupLink on Node {
      ... on ContactUs {
        id
        title
        path
      }
      ... on Faq {
        id
        title
        path
      }
      ... on Hyperlink {
        id
        title
        path: url
      }
      ... on LandingPage {
        id
        title
        path
      }
      ... on Message {
        id
        title
        path
      }
      ... on SignUpFormWidget {
        id
        title
        path
      }
      ... on Sitemap {
        id
        title
        path
      }
      ... on Subject {
        id
        title: navigationTitle
        path
      }
      ... on SustainabilityCategory {
        id
        title
        path
      }
      ... on SustainabilityLandingPage {
        id
        title
        path
      }
    }
    fragment FooterFlexibleHome on Website {
      brand {
        disclaimer
        disclaimerMessage {
          path
          teaserImage {
            description
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      domain {
        languages {
          ...LanguageSelectorLanguage
        }
      }
      legals {
        blurb {
          raw
        }
        socialChannelsLabel
        socialChannels {
          name
          url
          image {
            description
            localFile {
              publicURL
            }
          }
        }
        group1Label
        group1 {
          ...FooterFlexibleGroupLink
        }
        group2Label
        group2 {
          ...FooterFlexibleGroupLink
        }
        group3Label
        group3 {
          ...FooterFlexibleGroupLink
        }
        notices {
          ...FooterFlexibleGroupLink
        }
      }
    }
  `,
};
