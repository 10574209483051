/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, useEffect, useState } from "react";
import useScrollHandler from "../hooks/useScrollHandler";
import ClientOnly from "./ClientOnly";

export const NullLoader = () => null;

type Props = {
  delay?: number;
  dynamicImport(): Promise<{ default: React.ComponentType<any> }>;
  LoadingComponent?(): JSX.Element | null;
  props: any;
};

export default function LazyLoader({
  delay = 7000,
  dynamicImport,
  LoadingComponent = NullLoader,
  props,
}: Props) {
  const [loaded, setLoaded] = useState(false);
  const [hasScrolled, removeEventListenerRef] = useScrollHandler();

  useEffect(() => {
    const handleLoad = () => {
      setLoaded(true);
      removeEventListenerRef.current?.();
    };

    let timeout: NodeJS.Timeout;
    if (hasScrolled) {
      handleLoad();
    } else {
      timeout = setTimeout(handleLoad, delay);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, hasScrolled, removeEventListenerRef]);

  if (!loaded) {
    return <LoadingComponent />;
  }

  const LazyComponent = lazy(dynamicImport);
  return (
    <ClientOnly>
      <Suspense fallback={<LoadingComponent />}>
        <LazyComponent {...props} />
      </Suspense>
    </ClientOnly>
  );
}
