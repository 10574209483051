import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import React from "react";
import { FaviconBrandFragment } from "../../graphql-types";

type Props = {
  brand: FaviconBrandFragment;
};

export default function Favicon({ brand }: Props) {
  /**
   * NOTE: Favicons driven from Contentful are tricky as Sharp does not support ICO format.
   * Contentful has no mime-type validation, so sometimes we uploaded ICO, and sometimes
   * JPEG or PNG. ICO images were also not generated correctly to contain all image variations.
   * Having researched this, I think the best approach is to ignore ICO entirely and use PNG.
   * See: https://loqbooq.app/blog/add-favicon-modern-browser-guide
   */
  const favicon = brand.favicon;
  if (favicon.localFile.extension !== "png") {
    throw new Error("Favicons must be in PNG format");
  }

  const _32 = getSrc(favicon.localFile.childImageSharp?._32);
  const _16 = getSrc(favicon.localFile.childImageSharp?._16);
  const _48 = getSrc(favicon.localFile.childImageSharp?._48);
  const _192 = getSrc(favicon.localFile.childImageSharp?._192);
  const _167 = getSrc(favicon.localFile.childImageSharp?._167);
  const _180 = getSrc(favicon.localFile.childImageSharp?._180);
  return (
    <>
      {/* For all browsers */}
      <link rel="icon" type="image/png" sizes="32x32" href={_32} />
      <link rel="icon" type="image/png" sizes="16x16" href={_16} />
      {/* For Google and Chrome */}
      <link rel="icon" type="image/png" sizes="48x48" href={_48} />
      <link rel="icon" type="image/png" sizes="192x192" href={_192} />
      {/* For iPad */}
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="167x167"
        href={_167}
      />
      {/* For iPhone */}
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="180x180"
        href={_180}
      />
    </>
  );
}

export const fragments = graphql`
  fragment FaviconBrand on Brand {
    favicon {
      localFile {
        childImageSharp {
          _16: gatsbyImageData(width: 16, height: 16, formats: [PNG])
          _32: gatsbyImageData(width: 32, height: 32, formats: [PNG])
          _48: gatsbyImageData(width: 48, height: 48, formats: [PNG])
          _192: gatsbyImageData(width: 192, height: 192, formats: [PNG])
          _167: gatsbyImageData(width: 167, height: 167, formats: [PNG])
          _180: gatsbyImageData(width: 180, height: 180, formats: [PNG])
        }
        extension
      }
    }
  }
`;
