import type { ComponentPropsWithoutRef, ReactEventHandler } from "react";
import React, { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { trackCustomEvent } from "../utilities/analytics";
import { mergeRefs } from "../utilities/merge-refs";

/**
 * The props we want consumers to be able to override
 */
type SafeVideoProps = Pick<
  ComponentPropsWithoutRef<"video">,
  "autoPlay" | "className" | "controls" | "loop" | "muted" | "style" | "title"
>;

interface Props extends SafeVideoProps {
  "data-cy"?: string;
  "data-testid"?: string;
  path: string;
  poster: string;
  loading?: "auto" | "eager" | "lazy";
}

/**
 * A component that displays a video player element. Automatically handles
 * GA events for interaction and scrolling into view.
 */
export const VideoPlayer = React.forwardRef<HTMLVideoElement, Props>(
  (
    { path, controls = true, loading = "lazy", autoPlay = false, ...rest },
    forwardedRef
  ) => {
    const ref = useTrackInView(path);
    const eventHandler = useEventHandler(path);
    return (
      <video
        ref={mergeRefs(ref, forwardedRef)}
        // Overridable default props
        controls={controls}
        {...rest}
        // Default props
        onEnded={eventHandler}
        onError={eventHandler}
        onPause={eventHandler}
        onPlay={eventHandler}
        playsInline
        preload="none"
        autoPlay={autoPlay}
        // `muted` is required for `autoPlay` to work in most common browsers.
        // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
        muted={autoPlay}
      >
        <source src={path} type="video/mp4" />
      </video>
    );
  }
);

/**
 * Creates a handler that can be called when a video event occurs.
 * @param path The path of the video.
 * @returns The handler function.
 */
function useEventHandler(path: string) {
  return useCallback<ReactEventHandler<HTMLVideoElement>>(
    (e) => {
      trackCustomEvent({
        category: "Engagement",
        action: `video_${e.type}`,
        label: path,
      });
    },
    [path]
  );
}

/**
 * Tracks a GA event when the video is scrolled into view.
 * @param path The path of the video.
 * @returns A ref to attach to the video.
 */
function useTrackInView(path: string) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      trackCustomEvent({
        category: "Engagement",
        action: "video_in_view",
        label: path,
        nonInteraction: true,
      });
    }
  }, [inView, path]);

  return ref;
}
