// https://unilever-privacy.my.onetrust.com/request/v1/consentreceipts
import { useEffect } from "react";

type BeaconStatus =
  | "banner_accept"
  | "banner_beforeunload_interactionCount_0"
  | "banner_beforeunload_interactionCount_1"
  | "banner_beforeunload_no_interactionCount"
  | "banner_beforeunload_no_optanonCookie"
  | "banner_reject"
  | "banner_shown"
  | "beacon_load"
  | "no_MutationObserver"
  | `banner_${number}ms`;

const fetchOptions: RequestInit = {
  credentials: "omit",
  // keepalive works like `navigator.sendBeacon` and will continue the
  // request even if the browser/tab is closed
  keepalive: true,
  referrerPolicy: "same-origin",
};

function ping(beaconStatus: BeaconStatus) {
  fetch(`/ping?consent=${beaconStatus}`, fetchOptions);
}

function createFetchTimeout(ms: number) {
  return setTimeout(() => ping(`banner_${ms}ms`), ms);
}

function bannerClickHandler(event: Event) {
  const target = event.target as HTMLElement;
  if (target.id === "onetrust-reject-all-handler") {
    ping("banner_reject");
  }
  if (target.id === "onetrust-accept-btn-handler") {
    ping("banner_accept");
  }
}

function beforeUnloadHandler() {
  // otBannerSDK.js only fires the /consentreceipts beacon if:
  // - there's no OptanonCookie, or
  // - OptanonCookie interactionCount is 0 (or 1, if otBannerSDK.js incremented it before we found it)
  // For a baseline, I've aded more logs for each scenario, to see which is closest to theirs.
  const optanonCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("OptanonConsent"));
  if (!optanonCookie) {
    ping("banner_beforeunload_no_optanonCookie");
    return;
  }
  if (!optanonCookie.match(/interactionCount/)) {
    ping("banner_beforeunload_no_interactionCount");
  }
  if (optanonCookie.match(/interactionCount=0/)) {
    ping("banner_beforeunload_interactionCount_0");
  }
  if (optanonCookie.match(/interactionCount=1/)) {
    ping("banner_beforeunload_interactionCount_1");
  }
}

export default function OneTrustBeacons() {
  useEffect(() => {
    // Todo: We could add a polyfill for MutationObserver if we see the number of pings are high.
    // Eg https://gist.github.com/wintercounter/49863501f5085e0c3300
    if (typeof window.MutationObserver !== "function") {
      ping("no_MutationObserver");
      return;
    }

    let fetchTimeouts: Array<NodeJS.Timeout> = [];
    let bannerEl: HTMLElement | null;

    ping("beacon_load");

    const mutationObserverCallback: MutationCallback = (_, observer) => {
      bannerEl = document.body.querySelector("#onetrust-banner-sdk");
      if (!bannerEl) return;
      observer.disconnect();
      ping("banner_shown");
      window.addEventListener("beforeunload", beforeUnloadHandler);
      bannerEl.addEventListener("click", bannerClickHandler);
      fetchTimeouts = [5000, 10000, 15000].map(createFetchTimeout);
    };

    const observer = new MutationObserver(mutationObserverCallback);
    observer.observe(document.body, { childList: true });
    return () => {
      fetchTimeouts.forEach(clearTimeout);
      bannerEl?.removeEventListener("click", bannerClickHandler);
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      observer?.disconnect();
    };
  }, []);

  return null;
}
