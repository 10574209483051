import { useCallback, useState } from "react";

import useEventListener from "./useEventListener";

function useScrollHandler() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const handler = useCallback(() => setHasScrolled(true), [setHasScrolled]);

  const removeEventListenerRef = useEventListener("scroll", handler);

  return [hasScrolled, removeEventListenerRef] as const;
}

export default useScrollHandler;
