import { useState, useEffect } from "react";

type Props = {
  children: JSX.Element;
};

export default function ClientOnly({ children }: Props) {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender ? children : null;
}
