// From the excellent https://usehooks.com/useEventListener/

import { useRef, useEffect } from "react";

type HandlerFn = (e: Event) => void;
type RemoveFn = () => void;

function useEventListener(
  eventName: string,
  handler: HandlerFn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: HTMLElement = global as any
) {
  const savedHandler = useRef<HandlerFn>();
  const removeEventListenerRef = useRef<RemoveFn>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener: EventListenerOrEventListenerObject = (event) => {
        savedHandler?.current?.(event);
      };

      // Add event listener
      element.addEventListener(eventName, eventListener);

      removeEventListenerRef.current = () => {
        element.removeEventListener(eventName, eventListener);
      };

      // Remove event listener on cleanup
      return removeEventListenerRef.current;
    },
    [eventName, element] // Re-run if eventName or element changes
  );

  return removeEventListenerRef;
}

export default useEventListener;
