import cx from "classnames";
import React, { ComponentPropsWithoutRef } from "react";

import { useLexicon } from "../context/LexiconContext";

interface Props
  extends Omit<ComponentPropsWithoutRef<"a">, "children" | "href" | "id"> {
  contentId: string;
}

export const SkipNav: React.FC<Props> = ({ contentId, ...rest }) => {
  const lexicon = useLexicon();
  return (
    <a
      {...rest}
      className={cx(
        "absolute p-0 overflow-hidden border-0 [height:1px] [width:1px] [margin:-1px] [clip:rect(0_0_0_0)]",
        "focus:p-4 focus:fixed focus:[top:10px] focus:[left:10px] focus:bg-gray-300 focus:[z-index:9999] focus:w-auto focus:h-auto focus:[clip:auto]"
      )}
      href={`#${contentId}`}
    >
      {lexicon.skipToContent}
    </a>
  );
};
