import { withPrefix } from "gatsby";
import React, { ComponentPropsWithoutRef } from "react";
import { trackCustomEvent } from "../utilities/analytics";

export interface Props extends ComponentPropsWithoutRef<"a"> {
  href: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
}

const redirect = (href: string) => {
  window.location.assign(href);
};

export default function InternalLink({
  href,
  target,
  eventAction = "Link Click",
  eventCategory = "Engagement",
  eventLabel = href,
  children,
  onClick,
  ...rest
}: Props) {
  if (!href.startsWith("#")) {
    href = /^https?:\/\//.test(href) ? href : withPrefix(href);
  }
  return (
    <a
      {...rest}
      target={target}
      href={href}
      onClick={(event) => {
        onClick?.(event);

        let shouldRedirectCurrentTab = true;
        if (
          event.button !== 0 ||
          event.altKey ||
          event.ctrlKey ||
          event.metaKey ||
          event.shiftKey ||
          event.defaultPrevented
        ) {
          shouldRedirectCurrentTab = false;
        }

        if (target && target.toLowerCase() !== "_self") {
          shouldRedirectCurrentTab = false;
        }

        trackCustomEvent({
          category: eventCategory,
          action: eventAction,
          label: eventLabel,
          transport: shouldRedirectCurrentTab ? "beacon" : undefined,
          callback() {
            if (shouldRedirectCurrentTab) {
              redirect(href);
            }
          },
        });

        return false;
      }}
    >
      {children}
    </a>
  );
}
