/* eslint-disable @typescript-eslint/no-explicit-any */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import React, { ComponentPropsWithoutRef, ElementType } from "react";
import { RichTextReference } from "./types";
import { createRendererOptions } from "./config";

type Props<T extends React.ElementType<any>> = ComponentPropsWithoutRef<T> & {
  component?: React.ElementType<any>;
  raw: Document;
  references?: RichTextReference[];
};

/**
 * A component that renders rich text WYSIWYG content from Contentful (with lazy-loaded images)
 */
export function RichTextRenderer<T extends ElementType>({
  component: Component = "article",
  raw,
  references = [],
  ...rest
}: Props<T>) {
  return (
    <Component {...rest}>
      {documentToReactComponents(raw, createRendererOptions(references))}
    </Component>
  );
}
