import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Maybe } from "../../graphql-types";
import { trackCustomDimensions } from "../utilities/analytics";

type Props = {
  absolutePath: string;
  title: string;
  dmpAttributes?: Record<string, string>;
  pageType?: string;
  domain: {
    name: string;
    adobeConfig?: Maybe<{
      analytics__adobe__region: string;
    }>;
    countryCode: string;
    gaTrackingIds?: Array<string> | null;
    hasCookieQ: boolean;
  };
  brand: {
    name: string;
  };
  channel?: string;
};

const dmpAttributeToDimensionMap: Record<string, string> = {
  age: "dimension51",
  bodytype: "dimension49",
  cause: "dimension53",
  celebrities: "dimension52",
  colour: "dimension34",
  content: "dimension91",
  culture: "dimension54",
  entertainment: "dimension72",
  events: "dimension36",
  familysize: "dimension78",
  fashion: "dimension61",
  finefoodandcooking: "dimension114",
  gender: "dimension62",
  hairattributes: "dimension88",
  healthconcious: "dimension70",
  homemaking: "dimension47",
  indulgence: "dimension115",
  interest: "dimension9",
  lifeevents: "dimension71",
  lifestage: "dimension65",
  lifestyle: "dimension46",
  maritalstatus: "dimension48",
  master: "dimension118",
  moments: "dimension92",
  naturals: "dimension81",
  onsite: "dimension66",
  persona: "dimension10",
  places: "dimension97",
  price: "dimension39",
  problems: "dimension21",
  product: "dimension22",
  purchasedetails: "dimension45",
  seasonality: "dimension98",
  sport: "dimension50",
  time: "dimension116",
  travel: "dimension87",
  usagebehaviour: "dimension117",
  values: "dimension38",
  vegan: "dimension89",
  weather: "dimension107",
};

export function getDmpDimensions(
  dmpattributes: Record<string, string | undefined | null>
) {
  const output: Record<string, string> = {};
  for (const [dmpAttribute, inputValue] of Object.entries(dmpattributes)) {
    const dimension = dmpAttributeToDimensionMap[dmpAttribute];
    if (dimension && inputValue) {
      output[dimension] = inputValue;
    }
  }
  return output;
}

export default function AnalyticsDataLayer({
  absolutePath,
  title,
  dmpAttributes = {},
  pageType,
  brand,
  domain,
  channel,
}: Props) {
  if (!channel) {
    channel = isMobile ? "Mobile Site" : "Brand Site";
  }

  const splits = absolutePath
    .replace(".html", "")
    .replace(/-/g, " ")
    .split("/");
  const [subCategory1, subCategory2] = splits.slice(splits.length - 2);

  const digitalData = {
    adobeEvents: [],
    siteInfo: {
      channel,
      sitetype: "Digital 3.0",
      internalDomain: domain.name,
    },
    page: {
      pageInfo: {
        destinationURL: absolutePath,
        pageName: title,
      },
      category: {
        pageType,
        primaryCategory: channel,
        ...(pageType === "Article" && { subCategory1, subCategory2 }),
      },
      attributes: {
        ...(pageType === "Article" && { articleName: title }),
        contentType: title, // should be pageType surely.. but to match v2, we use title
        brandCategory: "Home Care",
        globalBrand: brand.name,
        localBrand: brand.name,
        ...(domain.adobeConfig && {
          country: domain.adobeConfig.analytics__adobe__region,
        }),
      },
      dmpattributes: dmpAttributes,
    },
    video: [],
    campaign: [],
    product: [],
    privacy: {
      accessCategories: [{ domains: [] }],
    },
    component: [],
    trackingInfo: {},
    promotion: [],
    areaInfo: {
      weather: "",
      temperature: "",
      aqi: "",
      uv: "",
      uv_range: "",
      aqi_range: "",
    },
    event: [],
  };

  useEffect(() => {
    const dimensions = {
      dimension1: digitalData.siteInfo.channel,
      dimension2: digitalData.page.attributes.globalBrand,
      dimension3: digitalData.page.attributes.localBrand,
      dimension4: digitalData.page.attributes.brandCategory,
      dimension5: digitalData.page.attributes.country,
      dimension6: digitalData.siteInfo.sitetype,
      dimension7: digitalData.page.category.pageType,
      dimension8: digitalData.page.attributes.contentType,
      dimension37: window.location.href,
      dimension109: window.navigator.userAgent,
    };

    const dmpDimensions = getDmpDimensions(digitalData.page.dmpattributes);
    trackCustomDimensions(
      { ...dimensions, ...dmpDimensions },
      domain.countryCode,
      domain.hasCookieQ,
      domain.gaTrackingIds
    );
  }, [
    digitalData.page.attributes.brandCategory,
    digitalData.page.attributes.contentType,
    digitalData.page.attributes.country,
    digitalData.page.attributes.globalBrand,
    digitalData.page.attributes.localBrand,
    digitalData.page.category.pageType,
    digitalData.page.dmpattributes,
    digitalData.siteInfo.channel,
    digitalData.siteInfo.sitetype,
    domain.countryCode,
    domain.hasCookieQ,
    domain.gaTrackingIds,
  ]);

  return (
    <script>{`
window.digitalData = ${JSON.stringify(digitalData, null, 2)}
    `}</script>
  );
}
