import cx from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

import { useLexicon } from "../context/LexiconContext";
import InternalLink from "./InternalLink";
import Nav from "./Nav";

type Props = {
  homePath: string;
  brandLogoDescription: string;
  brandLogoImage: IGatsbyImageData;
  absolutePath: string;
  className?: string;
};

export default function Header({
  homePath,
  brandLogoImage,
  absolutePath,
  className,
}: Props) {
  const lexicon = useLexicon();

  return (
    <div className="z-50 size-full bg-white header-wrapper">
      <header className={cx(className, "min-h-[6rem]")}>
        <div data-cy="logo" className="logo-wrapper">
          <InternalLink
            href={homePath}
            className="flex mr-auto flex-0 header-logo"
          >
            <GatsbyImage
              image={brandLogoImage}
              alt={lexicon.home}
              loading="eager"
            />
          </InternalLink>
        </div>
        <Nav absolutePath={absolutePath} />
      </header>
    </div>
  );
}
