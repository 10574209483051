import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import {
  GetFlagsQuery,
  LanguageSelectorLanguageFragment,
} from "../../graphql-types";
import useDomainPath from "../hooks/useDomainPath";
import iconSwitch from "../images/icon-switch.svg";
import GatsbyImage from "./GatsbyImage";

type Props = {
  languages: LanguageSelectorLanguageFragment[];
};

export default function LanguageSelector({ languages }: Props) {
  /**
   * This component assumes that there are only two languages.
   * We will need to refactor this in the future if a site has more than two.
   */
  const currentLang = useDomainPath();
  const data = useStaticQuery<GetFlagsQuery>(graphql`
    fragment FlagImage on File {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 26)
      }
    }

    query GetFlags {
      EN_GB: file(relativePath: { eq: "flag-uk.png" }) {
        ...FlagImage
      }
      FR_FR: file(relativePath: { eq: "flag-france.png" }) {
        ...FlagImage
      }
    }
  `);

  const altLang = languages.find((x) => x.path !== currentLang);
  if (!altLang) {
    throw new Error("Could not find the alternative language!");
  }

  const { locale, name, path } = altLang;
  // TODO: fix the types here
  const flag = data[locale as "EN_GB" | "FR_FR"];
  return (
    <a
      className="flex items-center justify-end p-2 text-sm font-medium bg-white border-t border-mercury text-tuna hover:underline"
      href={path}
    >
      {flag && (
        <GatsbyImage
          alt=""
          className="mr-2"
          data-placeholder-image
          image={flag.childImageSharp?.gatsbyImageData}
        />
      )}
      {name}
      <span className="px-2 py-1 ml-3 rounded-sm bg-[#002E6D]">
        <img alt="" className="w-4" data-placeholder-image src={iconSwitch} />
      </span>
    </a>
  );
}

export const fragments = {
  languages: graphql`
    fragment LanguageSelectorLanguage on Language {
      locale
      name
      path
    }
  `,
};
