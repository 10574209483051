import type { LegacyRef, MutableRefObject, RefCallback } from "react";

/**
 * Merges React refs into one. Useful when you're using a local and a forwared ref.
 * @param refs The refs to merge
 * @returns The merged refs
 */
export function mergeRefs<T>(
  ...refs: Array<MutableRefObject<T> | LegacyRef<T>>
): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
