import { OutboundLink, OutboundLinkProps } from "gatsby-plugin-google-gtag";
import React, { ComponentPropsWithoutRef } from "react";

import { useLexicon } from "../context/LexiconContext";

interface Props extends ComponentPropsWithoutRef<"a">, OutboundLinkProps {
  showExternalIcon?: boolean;
}

function unique<T>(arr: T[]) {
  return Array.from(new Set(arr)).filter(Boolean);
}

export const cleanipediaDomains = [
  "https://www.cleanipedia.com",
  "https://www.temizsozluk.com",
  "https://www.getsetclean.in",
];

function getRel(href: Props["href"]) {
  if (href && cleanipediaDomains.some((domain) => href.indexOf(domain) > -1)) {
    return ["noopener", "noreferrer"]; // exclude `nofollow` for Cleanipedia links
  }
  return ["nofollow", "noopener", "noreferrer"];
}

export default function ExternalLink({
  rel = "",
  target,
  showExternalIcon = target === "_blank",
  children,
  title,
  href,
  ...rest
}: Props) {
  const lexicon = useLexicon();
  if (target === "_blank") {
    title = `${title ? `${title} | ` : ""}${lexicon.opensInNewTab}`;
  } else if (title === children) {
    title = ""; // a11y: don't duplicate "text" between <a title="text">text</a>
  }
  const relArray = getRel(href);
  return (
    <OutboundLink
      rel={unique([...relArray, rel]).join(" ")}
      target={target}
      title={title}
      href={href}
      {...rest}
    >
      {children}
      {showExternalIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -256 1850 1850"
          aria-label={target === "_blank" ? lexicon.opensInNewTab : undefined}
          className="inline-block icon-external-link"
        >
          <path
            d="M1438.373 818.95v320q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-320q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19q-26 0-45-19l-176-176-652 652q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45q19-19 45-19h512q26 0 45 19t19 45z"
            fill="#3FFFFFF"
          />
        </svg>
      )}
    </OutboundLink>
  );
}
