import { Options } from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Inline,
  INLINES,
  MARKS,
  Text,
} from "@contentful/rich-text-types";
import loadable from "@loadable/component";
import React from "react";
import { RichTextReference } from "./types";

const AssetHyperlink = loadable(() => import("./AssetHyperlink"));
const EmbeddedImage = loadable(() => import("./EmbeddedImage"));
const VideoPlayer = loadable(() => import("../VideoPlayer"), {
  resolveComponent: (components) => components.VideoPlayer,
});
const ExternalLink = loadable(() => import("../ExternalLink"));
const ImageWithCaption = loadable(() => import("./ImageWithCaption"));
const InternalLink = loadable(() => import("../InternalLink"));
const YoutubeEmbed = loadable(() => import("./YoutubeEmbed"));

const IMAGE_WITH_CAPTION_TYPE = "imageWithCaption";
const YOUTUBE_EMBED_TYPE = "YoutubeEmbed";
const isAbsolute = (url: string) => /^https?:\/\//i.test(url);
const isMailto = (url: string) => /^mailto:/i.test(url);
const matchYoutubeEmbed = (url: string) =>
  url.match(/^https?:\/\/www.youtube(-nocookie)?.com\/embed\/(.*)/);

export function createRendererOptions(
  references: RichTextReference[]
): Options {
  return {
    renderNode: {
      [INLINES.ASSET_HYPERLINK](node) {
        return <AssetHyperlink assets={references} node={node as Inline} />;
      },
      [INLINES.ENTRY_HYPERLINK](node) {
        const content = node.content[0] as Text;
        const href = `/${node.data.target.fields.slug}.html`.replace(
          /^\/\//,
          ""
        );
        return <InternalLink href={href}>{content.value}</InternalLink>;
      },
      [INLINES.HYPERLINK](node) {
        const content = node.content[0] as Text;
        if (!content) {
          console.warn("No content found in hyperlink node", node);
          return;
        }

        // `uri` is absolute, but if it's for this website, treat as internal.
        const href = node.data.uri.replace(
          `https://www.${process.env.GATSBY_SITE_DOMAIN}${process.env.GATSBY_PATH_PREFIX}`,
          ""
        );

        if (isAbsolute(href) || isMailto(href)) {
          return (
            <ExternalLink href={href} target="_blank">
              {content.value}
            </ExternalLink>
          );
        }
        return <InternalLink href={href}>{content.value}</InternalLink>;
      },
      [BLOCKS.EMBEDDED_ASSET](node) {
        const { url, contentType } = node.data.target.fields.file;
        const asset = references.find((_) => _.sourceUrl === url);
        if (!asset) {
          throw new Error(`You must pass the asset with URL: ${url}.`);
        }

        if (contentType.match(/^image\//)) {
          return <EmbeddedImage reference={asset} />;
        }
        if (contentType.match(/^video\//) && asset.cdnVideo) {
          return (
            <VideoPlayer
              className="w-full"
              data-testid="embedded-video"
              poster={asset.cdnVideo.posterPath}
              path={asset.cdnVideo.path}
            />
          );
        }

        throw new Error(
          `Unsupported embedded asset type passed to renderer: ${contentType}.`
        );
      },
      [INLINES.EMBEDDED_ENTRY](node) {
        const { fields, sys } = node.data.target;
        if (sys.contentType.sys.id === YOUTUBE_EMBED_TYPE) {
          const { title, url } = fields;
          const match = matchYoutubeEmbed(url as string);
          if (!match) {
            throw new Error(
              `You must pass the YoutubeEmbed url in the correct format: ${url}.`
            );
          }
          return <YoutubeEmbed title={title} embedID={match[2]} />;
        }

        throw new Error(
          `Unsupported inline entry type passed to renderer: ${sys.contentType.sys.id}.`
        );
      },
      [BLOCKS.EMBEDDED_ENTRY](node) {
        const { fields, sys } = node.data.target;
        if (sys.contentType.sys.id === IMAGE_WITH_CAPTION_TYPE) {
          const { url } = fields.image.fields.file;
          const asset = references.find((a) => a.sourceUrl === url);
          if (!asset) {
            throw new Error(`You must pass the asset with URL: ${url}.`);
          }

          return (
            <ImageWithCaption reference={asset} caption={fields.caption} />
          );
        }

        if (sys.contentType.sys.id === YOUTUBE_EMBED_TYPE) {
          const { title, url } = fields;
          const match = matchYoutubeEmbed(url as string);
          if (!match) {
            throw new Error(
              `You must pass the YoutubeEmbed url in the correct format: ${url}.`
            );
          }
          return <YoutubeEmbed title={title} embedID={match[2]} />;
        }

        throw new Error(
          `Unsupported embedded entry type passed to renderer: ${sys.contentType.sys.id}.`
        );
      },
    },
    renderMark: {
      [MARKS.BOLD](node) {
        return <span className="font-bold">{node}</span>;
      },
    },
  };
}
