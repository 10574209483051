import { Script } from "gatsby";
import React from "react";

type Props = {
  id: string;
};

const MyTargetAnalytics = ({ id }: Props) => (
  <>
    <Script id={`my-target-analytics-${id}`} type="text/javascript">{`
var _tmr = window._tmr || (window._tmr = []);
_tmr.push({id: '${id}', type: 'pageView', start: (new Date()).getTime()});
(function (d, w, id) {
 if (d.getElementById(id)) return;
 var ts = d.createElement('script'); ts.type = 'text/javascript'; ts.async = true; ts.id = id;
 ts.src = 'https://top-fwz1.mail.ru/js/code.js';
 var f = function () {var s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ts, s);};
 if (w.opera == '[object Opera]') { d.addEventListener('DOMContentLoaded', f, false); } else { f(); }
})(document, window, 'topmailru-code');
`}</Script>
  </>
);

export default MyTargetAnalytics;

export const MyTargetAnalyticsBody = ({ id }: Props) => {
  return (
    <>
      <noscript>
        <div>
          <img
            src={`https://top-fwz1.mail.ru/counter?id=${id};js=na`}
            style={{ border: 0, position: "absolute", left: "-9999px" }}
            alt="Top.Mail.Ru"
          />
        </div>
      </noscript>
    </>
  );
};
