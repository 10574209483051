import React from "react";
import { WebSite } from "schema-dts";
import JSONLDTag from "./JSONLDTag";

type Props = {
  absolutePath: string;
  name: string;
};

export default function JSONLDWebsite(props: Props) {
  return (
    <JSONLDTag<WebSite>
      json={{
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: props.name,
        url: props.absolutePath,
      }}
    />
  );
}
