import React from "react";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";

export default function Image(props: GatsbyImageProps) {
  if (!props.image) {
    return (
      <span data-testid="gatsby-image-error" className="gatsby-image-error" />
    );
  }
  return <GatsbyImage data-testid="gatsby-image" {...props} data-ot-ignore />;
}
